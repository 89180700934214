<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="개정" :noHeader="true" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="param.equipmentCd">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-2">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-12 col-lg-12">
          <c-card title="설비 기본정보" class="cardClassDetailForm">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="param.equipmentCd"
                :comboItems="revdata"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="equipmentCd"
                name="selectedEquipmentCd"
                label=""
                v-model="selectedEquipmentCd"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                 <c-btn
                  v-show="editable && param.equipmentCd && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="setRevision" />
                <c-btn
                  v-show="editable && param.equipmentCd && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="cancelRevision" />
                <c-btn label="QR코드 출력" v-if="editable" icon="save_alt" @btnClicked="btnQrCode" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설비Tag"
                  name="equipmentNo"
                  v-model="data.equipmentNo">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설비관리번호"
                  name="equipmentNum"
                  v-model="data.equipmentNum">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설비명"
                  name="equipmentName"
                  v-model="data.equipmentName">
                </c-text>
              </div>
              <div class="col-2">
                <c-equip-class
                  :required="true"
                  :editable="editable"
                  label="설비유형"
                  name="equipmentTypeCd"
                  v-model="data.equipmentTypeCd">
                </c-equip-class>
              </div>
              <div class="col-2">
                <c-func-location 
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="editable" 
                  label="기능위치" 
                  name="funcLocationCd" 
                  v-model="data.funcLocationCd" />
              </div>
              <div class="col-2">
                <c-plant
                  :required="true"
                  :editable="editable && !updateMode"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-dept-multi :required="true" label="관리부서" :parentCheckDepts="data.managementDepts" :plantCd="data.plantCd" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-4">
                <c-process
                  :editable="editable"
                  label="단위공정"
                  multiple="multiple"
                  name="subProcessCd"
                  v-model="data.subProcessCd">
                </c-process>
              </div>
              <div class="col-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="MDM_EQUIP_GRADE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="equipGradeCd"
                  label="설비등급"
                  v-model="data.equipGradeCd"
                ></c-select>
              </div>
              <div class="col-1">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="수량"
                  name="amount"
                  type="number"
                  v-model="data.amount">
                </c-text>
              </div>
              <div class="col-3">
                <c-cost-center
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="editable" 
                  label="코스트센터" 
                  name="costCenterCd" 
                  v-model="data.costCenterCd" />
              </div>
              <div class="col-2">
                <c-text
                  :editable="false"
                  label="최초작성자"
                  name="regUserName"
                  type="regUserName"
                  v-model="data.regUserName">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="false"
                  label="최초작성일시"
                  name="regDtStrTime"
                  type="regDtStrTime"
                  v-model="data.regDtStrTime">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="false"
                  label="최근수정자"
                  name="chgUserName"
                  type="chgUserName"
                  v-model="data.chgUserName">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="false"
                  label="최근수정일시"
                  name="chgDtStrTime"
                  type="chgDtStrTime"
                  v-model="data.chgDtStrTime">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="설비가동 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-4">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  label="시작 가동일"
                  name="operationStartDate"
                  v-model="data.operationStartDate"
                  @datachange="getHolidaies"
                />
              </div>
              <div class="col-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  type="number"
                  suffix="시간"
                  label="하루 가동시간"
                  name="operateTime"
                  v-model="data.operateTime"
                  @dataChange="dataChange"
                />
              </div>
              <div class="col-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="휴무일 여부"
                  name="holidayFlag"
                  v-model="data.holidayFlag"
                  @datachange="dataChange"
                />
              </div>
              <div class="col-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  suffix="시간"
                  label="부하시간"
                  name="lapseTime"
                  v-model="data.lapseTime"
                />
              </div>
              <div class="col-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  suffix="시간"
                  label="총 가동시간"
                  name="operationSumTime"
                  v-model="data.operationSumTime"
                />
              </div>
              <div class="col-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  suffix="%"
                  label="가동률"
                  name="operationPercent"
                  v-model="data.operationPercent"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="table"
            title="가동시간 목록(개정포함)"
            :columns="grid.columns"
            :data="equipList"
            :gridHeight="grid.height"
            :editable="false"
            :hideBottom="true"
            :isExcelDown="false"
            :filtering="false"
            :isFullScreen="false"
            :columnSetting="false"
          >
            <template slot="suffixTitle">
              <q-chip 
                v-if="lapSum" 
                dense 
                color="gray" 
                text-color="main-custom" 
                icon="bookmark">
                {{'부하시간 합계: ' + lapSum}}
              </q-chip>
              <q-chip 
                v-if="operSum" 
                dense 
                color="gray" 
                text-color="main-custom" 
                icon="bookmark">
                {{'가동시간 합계: ' + operSum}}
              </q-chip>
            </template>
          </c-table>
        </div>
        <div class="col-md-3 col-lg-3">
          <c-card title="설치관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-vendor
                  :editable="editable"
                  label="정비업체"
                  name="maintenVendorCd"
                  v-model="data.maintenVendorCd">
                </c-vendor>
              </div>
              <div class="col-6">
                <c-datepicker
                  label="설치일"
                  name="installDate"
                  v-model="data.installDate"
                />
              </div>
              <div class="col-6">
                <c-vendor
                  :editable="editable"
                  label="설치업체"
                  name="installVendor"
                  v-model="data.installVendor">
                </c-vendor>
              </div>
              <div class="col-6">
                <c-datepicker
                  label="가동일"
                  name="opertationDate"
                  v-model="data.opertationDate"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-3 col-lg-3">
          <c-card title="설치상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="검사합격번호"
                  name="checkPassNo"
                  v-model="data.checkPassNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                    label="합격일"
                    name="checkPassDate"
                    v-model="data.checkPassDate"
                  />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="폐기여부"
                  name="discardFlag"
                  v-model="data.discardFlag"
                />
              </div>
              <div class="col-6">
                <c-datepicker
                  :editable="editable"
                  label="폐기일"
                  name="discardDate"
                  v-model="data.discardDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="점검관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <!-- <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="법정설비여부"
                  name="lawEquipmentFlag"
                  v-model="data.lawEquipmentFlag"
                  @datachange="datachange"
                />
              </div>
              <div class="col-3">
                <c-select
                  :required="data.lawEquipmentFlag == 'Y'"
                  :editable="editable"
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="inspectionCycleCd"
                  label="법정점검주기"
                  v-model="data.inspectionCycleCd"
                ></c-select>
              </div> -->
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="예방점검 여부"
                  name="operFlag"
                  v-model="data.operFlag"
                />
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="selfCycleCd"
                  label="예방점검주기"
                  v-model="data.selfCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="PM여부"
                  name="pmFlag"
                  v-model="data.pmFlag"
                />
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="pmCycleCd"
                  label="PM주기"
                  v-model="data.pmCycleCd"
                ></c-select>
              </div>
              <!-- <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="예측정비여부"
                  name="predictiveFlag"
                  v-model="data.predictiveFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="고장정비여부"
                  name="cmFlag"
                  v-model="data.cmFlag"
                />
              </div> -->
              <div class="col-12">
                <c-multi-select
                  :editable="editable"
                  codeGroupCd="EQUIP_RELATED_LAW_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="설비관련 법규(인허가)"
                  name="relatedLaws"
                  v-model="data.relatedLaws">
                </c-multi-select>
              </div>
            </template>
          </c-card>
          <!-- <c-card title="법규관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-4">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="PSM 대상 여부"
                  name="psmFlag"
                  v-model="data.psmFlag"
                />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="유해위험기계기구 여부"
                  name="hazardousFlag"
                  v-model="data.hazardousFlag"
                />
              </div>
            </template>
          </c-card> -->
        </div>
        <div class="col-md-6 col-lg-6" style="margin-top:14px;">
          <c-upload
            :attachInfo="attachInfo"
            :editable="editable"
            :isPhoto="true"
            label="설비 사진">
          </c-upload>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-info',
  watch: {
    'data.discardFlag': function() {
      if (this.data.discardFlag == 'N') {
        this.data.discardDate = '';
      }
    }
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        equipmentNum: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentTypeCd: '',
        equipmentName: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EQUIPMENT_PHOTO',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      isRevision: false,
      revdata: [],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      serverName: '',
      redirectUrl: '',
      saveUrl: transactionConfig.mdm.equipment.info.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      revlistUrl: '',
      selectedEquipmentCd: '',
      data: {
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentNum: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        operFlag: 'N', // 운전정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N',
        funcLocationCd: '', // 기능위치
        costCenterCd: '', // 코스트센터
        maintenVendorCd: '',
        planNonOperate: 'N',
        equipGradeCd: null,
        groupId: '',  // 일련번호 키
        revisionNum: '',  // 개정번호
        revisionContent: '제정',  // 개정사유
        revisionFlag: 'N',
        operatioStartDate: '', 
        holidayFlag: 'N', 
        operateTime: '', 
        operationList: [],
        breakList: [],
        lapseTime: '',
        operationSumTime: '',
        operationPercent: '',
        revEquipList: [],
      },
      holidayUrl: '',
      holidayList: [],
      equipList: [],
      operateUrl: '',
      sumBreakTime: 0,
      sumBreakTime2: 0,
      
      grid: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'operationStartDate',
            field: 'operationStartDate',
            label: '시작 가동일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'operateTime',
            field: 'operateTime',
            label: '하루 가동시간',
            align: 'right',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'lapseTime',
            field: 'lapseTime',
            label: '부하시간',
            align: 'right',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'operationSumTime',
            field: 'operationSumTime',
            label: '총 가동시간',
            align: 'right',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
        height: '200px',
      },
      lapSum: 0,
      operSum: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.redirectUrl = selectConfig.mdm.equipment.qrUrl.url;
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.equipment.get.url;
      this.revlistUrl = selectConfig.mdm.equipment.get.url + '/revs';
      this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
      this.checkUrl = selectConfig.mdm.equipment.check.url;
      this.holidayUrl = selectConfig.sys.holiday.list.url;
      this.operateUrl = selectConfig.mdm.equipment.operation.list.url;
      this.serverUrl();
      this.getData();
      this.getHolidaies();
      this.getOperateData();
    },
    getOperateData() {
      if (this.param.equipmentCd) {
        this.$http.url = this.operateUrl + '/revs';
        this.$http.type = 'GET';
        this.$http.param = {
          groupId: this.param.equipmentCd
        };
        this.$http.request((_result) => {
          this.equipList = _result.data
          this.revOperationTimes();
        })
      }
    },
    getHolidaies() {
      if (this.data.operationStartDate) {
        this.$http.url = this.holidayUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          holidayFlag: 'C'
        }
        this.$http.request((_result) => {
          this.holidayList = this.$_.map(_result.data, 'holidayDt');
          this.getOperateData();
          this.dataChange();
        },);
      }
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    getData() {
      this.isRevision = false;
      if (this.param.equipmentCd) {
        this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
        this.getDetail(this.param.equipmentCd, true);
        this.selectedEquipmentCd = this.param.equipmentCd
      } 
    },
    getDetail(_equipmentCd, _check) {
      this.$http.url = this.detailUrl;
      this.$http.param = {equipmentCd: _equipmentCd}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.isRevision = false;
        this.data.revisionFlag = 'N';
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if (_result.data.equipmentCd == this.param.equipmentCd) {
          this.editable = this.$route.meta.editable;
          this.$set(this.param, 'updateFlag', this.selectedEquipmentCd + '|false')
        } else {
          this.editable = false;
          this.$set(this.param, 'updateFlag', this.selectedEquipmentCd + '|true')
        }
        this.updateMode = true;
        this.param.plantName = _result.data.plantName;
        this.param.plantCd = _result.data.plantCd;
        // this.param.equipmentCd = _result.data.equipmentCd;
        this.param.equipmentNo = _result.data.equipmentNo;
        this.param.equipmentTypeCd = _result.data.equipmentTypeCd;
        this.param.equipmentTypeName = _result.data.equipmentTypeName;
        this.param.equipmentName = _result.data.equipmentName;
        this.param.processCds = _result.data.processCds;
        this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
        this.$emit('setEditable', this.editable, _equipmentCd)
      },);
    },
    saveInfo() {
      if (this.param.equipmentCd) {
        this.saveUrl = transactionConfig.mdm.equipment.info.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.isRevision) {
        saveMsg = '현재버전을 개정('+this.data.revisionNum+')하여 저장하시겠습니까?';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.equipmentNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: saveMsg,
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;

                  this.$set(this.data, 'revEquipList', this.equipList)
                  // 개정시
                  if (this.isRevision) {
                    this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
                    this.mappingType = 'POST';
                    this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
                    this.data.revisionFlag = 'Y';
                  }
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 설비Tag가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.equipmentCd = result.data.equipmentCd;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST' && !this.isRevision) {
        this.$emit('insertEquipInfo', result.data.equipmentCd);
      }
      this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.param.equipmentCd = result.data.equipmentCd
      this.getData();
      this.getOperateData();
    },
    dataChange() {
      this.sumBreakTime = 0;
      this.sumBreakTime2 = 0;
      let count1 = 0;
      let start = this.$comm.moment(this.data.operationStartDate)
      let end = this.$comm.moment(this.$comm.getToday())
      count1 = this.$comm.getDatediff(start, end) + 1;
      // let breakDate = [];
      
      this.$_.forEach(this.data.operationList, _item => {
        this.sumBreakTime += Number(_item.breakTime)
        // breakDate.push(_item.breakDtm[0].split(' ')[0])
        // breakDate.push(_item.breakDtm[1].split(' ')[0])
        // console.log(this.$comm.moment(_item.breakDtm[0].split(' ')[0]))
        // console.log(this.$comm.moment(_item.breakDtm[1].split(' ')[0]))
      })
      this.$_.forEach(this.data.breakList, _item => {
        this.sumBreakTime2 += Number(_item.breakdownTime)
      })
      if (this.data.operationStartDate && this.data.operateTime) {
        if (this.data.holidayFlag === 'N') {

          this.data.lapseTime = Number(this.data.operateTime) * count1
          this.data.operationSumTime = (Number(this.data.operateTime) * count1) - this.sumBreakTime - this.sumBreakTime2
          this.data.operationPercent = ((((Number(this.data.operateTime) * count1) - this.sumBreakTime - this.sumBreakTime2) / (Number(this.data.operateTime) * count1)) * 100).toFixed(5)
        } else {
          let start = new Date(this.data.operationStartDate); 
          let end = new Date(this.$comm.getToday());
          let count2 = 1;
          let temp_date = start;
          while (temp_date.getTime() <= end.getTime()) {  
            let tmp = temp_date.getDay();
            let month = temp_date.getMonth() + 1;
            if (Number(temp_date.getMonth() + 1) < 10) {
              month = '0' + Number(temp_date.getMonth() + 1);
            }
            let day = temp_date.getDate();
            if (Number(temp_date.getDate()) < 10) {
              day = '0' + temp_date.getDate();
            }
            let date = temp_date.getFullYear() +'-' + month  + '-' + day
            
            if(tmp == 0 || tmp == 6) {
                // 주말
            } else if (this.holidayList.indexOf(date) >= 0)  {
                // 공휴일
            } else {
                // 평일
                count2++;         
            }
            temp_date.setDate(start.getDate() + 1); 
          }
          
          this.data.lapseTime = Number(this.data.operateTime) * count2 
          this.data.operationSumTime = (Number(this.data.operateTime) * count2) - this.sumBreakTime - this.sumBreakTime2
          this.data.operationPercent = ((((Number(this.data.operateTime) * count2) - this.sumBreakTime - this.sumBreakTime2) / (Number(this.data.operateTime) * count2)) * 100).toFixed(5)
        }
      }
    },
    revOperationTimes() {
      this.lapSum = 0;
      this.operSum = 0;
      this.$_.forEach(this.equipList, _item => {
        // 1. 고장시간 계산
        // 2. 비가동시간 계산
        // 3. 휴무일과 고장/비가동일이 겹칠때 
        // 4. 개정된 가동시간 계산 

        let newDate = _item.operationStartDate
        let sumBreakTime1 = 0;
        let sumBreakTime2 = 0;
        let sumBreakDates1 = [];
        let sumBreakDates2 = [];
        this.$_.forEach(_item.operationList, _operate => {
          sumBreakTime1 += Number(_operate.breakTime)
          let start = new Date(_operate.breakStartDt); 
          let end = new Date(_operate.breakEndDt);

          let temp_date = start;

          while (temp_date.getTime() <= end.getTime()) {  
            let month = temp_date.getMonth() + 1;
            if (Number(temp_date.getMonth() + 1) < 10) {
              month = '0' + Number(temp_date.getMonth() + 1);
            }
            let day = temp_date.getDate();
            if (Number(temp_date.getDate()) < 10) {
              day = '0' + temp_date.getDate();
            }
            let date = temp_date.getFullYear() +'-' + month  + '-' + day
            
            temp_date.setDate(start.getDate() + 1); 
            sumBreakDates1.push(date);
          }
        })
        this.$_.forEach(_item.breakList, _break => {
          sumBreakTime2 += Number(_break.breakdownTime)
          let start = new Date(_break.breakdownStartDt); 
          let end = new Date(_break.breakdownEndDt);

          let temp_date = start;

          while (temp_date.getTime() <= end.getTime()) {  
            let month = temp_date.getMonth() + 1;
            if (Number(temp_date.getMonth() + 1) < 10) {
              month = '0' + Number(temp_date.getMonth() + 1);
            }
            let day = temp_date.getDate();
            if (Number(temp_date.getDate()) < 10) {
              day = '0' + temp_date.getDate();
            }
            let date = temp_date.getFullYear() +'-' + month  + '-' + day
            
            temp_date.setDate(start.getDate() + 1); 
            sumBreakDates2.push(date);
          }
        })
        if (_item.holidayFlag === 'Y') {
          if (!_item.endDate) {
            let start = new Date(newDate)
            let end = new Date(this.$comm.getToday())
            let count = 1;

            let temp_date = start;
            while (temp_date.getTime() <= end.getTime()) {  
              let tmp = temp_date.getDay();
              let month = temp_date.getMonth() + 1;
              if (Number(temp_date.getMonth() + 1) < 10) {
                month = '0' + Number(temp_date.getMonth() + 1);
              }
              let day = temp_date.getDate();
              if (Number(temp_date.getDate()) < 10) {
                day = '0' + temp_date.getDate();
              }
              let date = temp_date.getFullYear() +'-' + month  + '-' + day
              
              if(tmp == 0 || tmp == 6) {
                  // 주말
              } else if (this.holidayList.indexOf(date) >= 0)  {
                  // 공휴일
              } 
              // else if (sumBreakDates1.indexOf(date) >= 0) {
              //   sumBreakTime1 = sumBreakTime1 - _item.operateTime
              //     // 고장일
              // } else if (sumBreakDates2.indexOf(date) >= 0) {
              //   sumBreakTime2 = sumBreakTime2 - _item.operateTime
              //     // 고장일
              // } 
              else {
                  // 평일
                  count++;         
              }
              temp_date.setDate(start.getDate() + 1); 
            }
            _item.lapseTime = Number(_item.operateTime) * count
            _item.operationSumTime = (Number(_item.operateTime) * count) - sumBreakTime1 - sumBreakTime2
            if (_item.operationSumTime < 0) {
              _item.operationSumTime = 0
            }
            _item.operationPercent = (_item.operationSumTime / _item.lapseTime * 100).toFixed(5)
            this.lapSum += parseInt(_item.lapseTime);
            this.operSum += parseInt(_item.operationSumTime);
            // 사이
          } else {
            let start = new Date(newDate)
            let end = new Date(_item.endDate)
            let count = 1;

            let temp_date = start;
            while (temp_date.getTime() <= end.getTime()) {  
              let tmp = temp_date.getDay();
              let month = temp_date.getMonth() + 1;
              if (Number(temp_date.getMonth() + 1) < 10) {
                month = '0' + Number(temp_date.getMonth() + 1);
              }
              let day = temp_date.getDate();
              if (Number(temp_date.getDate()) < 10) {
                day = '0' + temp_date.getDate();
              }
              let date = temp_date.getFullYear() +'-' + month  + '-' + day
              
              if(tmp == 0 || tmp == 6) {
                  // 주말
              } else if (this.holidayList.indexOf(date) >= 0)  {
                  // 공휴일
              } 
              // else if (sumBreakDates1.indexOf(date) >= 0) {
              //   sumBreakTime1 = sumBreakTime1 - _item.operateTime
              //     // 고장일
              // } 
              // else if (sumBreakDates2.indexOf(date) >= 0) {
              //   sumBreakTime2 = sumBreakTime2 - _item.operateTime
              //     // 고장일
              // } 
              else {
                  // 평일
                  count++;         
              }
              temp_date.setDate(start.getDate() + 1); 
            }

            _item.lapseTime = Number(_item.operateTime) * count
            _item.operationSumTime = (Number(_item.operateTime) * count) - sumBreakTime1 - sumBreakTime2
            if (_item.operationSumTime < 0) {
              _item.operationSumTime = 0
            }
            _item.operationPercent = (_item.operationSumTime / _item.lapseTime * 100).toFixed(5)

            this.lapSum += parseInt(_item.lapseTime);
            this.operSum += parseInt(_item.operationSumTime);
          // 최초기준일이 최초가동일보다 최근일일때
          }
        } else {
          if (!_item.endDate) {
            let start = this.$comm.moment(newDate)
            let end = this.$comm.moment(this.$comm.getToday())
            let count = this.$comm.getDatediff(start, end) + 1;

            _item.lapseTime = Number(_item.operateTime) * count

            _item.operationSumTime = (Number(_item.operateTime) * count) - sumBreakTime1 - sumBreakTime2
            if (_item.operationSumTime < 0) {
              _item.operationSumTime = 0
            }
            _item.operationPercent = (_item.operationSumTime / _item.lapseTime * 100).toFixed(5)
            this.lapSum += parseInt(_item.lapseTime);
            this.operSum += parseInt(_item.operationSumTime);
            // 사이
          } else {
            let start = this.$comm.moment(newDate)
            let end = this.$comm.moment(_item.endDate)
            let count = this.$comm.getDatediff(start, end);

            _item.lapseTime = Number(_item.operateTime) * count
            _item.operationSumTime = (Number(_item.operateTime) * count) - sumBreakTime1 - sumBreakTime2
            if (_item.operationSumTime < 0) {
              _item.operationSumTime = 0
            }
            _item.operationPercent = (_item.operationSumTime / _item.lapseTime * 100).toFixed(5)

            this.lapSum += parseInt(_item.lapseTime);
            this.operSum += parseInt(_item.operationSumTime);
          // 최초기준일이 최초가동일보다 최근일일때
          }
        }
      })
    },
    datachange() {
      if (this.data.lawEquipmentFlag === 'N') {
        this.data.inspectionCycleCd = null;
      }
    },
    btnQrCode() {
      let seldata = [];
      seldata.push(this.data)
      this.popupOptions.title = "설비 QR코드"; 
      this.popupOptions.param = {
        selectData: seldata,
      };
      this.popupOptions.target = () => import(`${"./equipmentQr.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '800px';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.param.equipmentCd)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    cancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.revdata = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.data.equipmentCd) {
          this.selectedEquipmentCd = row;
          this.getDetail(this.selectedEquipmentCd, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedEquipmentCd)
        }
      }
    },
  }
};
</script>