var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _vm.param.equipmentCd
                  ? _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm revisionLayout",
                        attrs: {
                          title: "개정",
                          noHeader: true,
                          topClass: "topcolor-orange",
                        },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-2" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "개정번호",
                                        name: "revisionNum",
                                      },
                                      model: {
                                        value: _vm.data.revisionNum,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "revisionNum", $$v)
                                        },
                                        expression: "data.revisionNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-2" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "개정일시",
                                        name: "regDtStr",
                                      },
                                      model: {
                                        value: _vm.data.regDtStr,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regDtStr", $$v)
                                        },
                                        expression: "data.regDtStr",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-2" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "개정자",
                                        name: "regUserName",
                                      },
                                      model: {
                                        value: _vm.data.regUserName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regUserName", $$v)
                                        },
                                        expression: "data.regUserName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "개정사유",
                                        name: "revisionContent",
                                      },
                                      model: {
                                        value: _vm.data.revisionContent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "revisionContent",
                                            $$v
                                          )
                                        },
                                        expression: "data.revisionContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "설비 기본정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-select" },
                      [
                        _vm.param.equipmentCd
                          ? _c("c-select", {
                              attrs: {
                                editable: !_vm.isRevision,
                                comboItems: _vm.revdata,
                                type: "custom",
                                typetext: "개정이력",
                                itemText: "revisionNum",
                                itemValue: "equipmentCd",
                                name: "selectedEquipmentCd",
                                label: "",
                              },
                              on: { input: _vm.rowClickRev },
                              model: {
                                value: _vm.selectedEquipmentCd,
                                callback: function ($$v) {
                                  _vm.selectedEquipmentCd = $$v
                                },
                                expression: "selectedEquipmentCd",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.param.equipmentCd &&
                                    !_vm.isRevision,
                                  expression:
                                    "editable && param.equipmentCd && !isRevision",
                                },
                              ],
                              attrs: { label: "개정", icon: "restart_alt" },
                              on: { btnClicked: _vm.setRevision },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.param.equipmentCd &&
                                    _vm.isRevision,
                                  expression:
                                    "editable && param.equipmentCd && isRevision",
                                },
                              ],
                              attrs: { label: "개정취소", icon: "restart_alt" },
                              on: { btnClicked: _vm.cancelRevision },
                            }),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "QR코드 출력",
                                    icon: "save_alt",
                                  },
                                  on: { btnClicked: _vm.btnQrCode },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "설비Tag",
                              name: "equipmentNo",
                            },
                            model: {
                              value: _vm.data.equipmentNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentNo", $$v)
                              },
                              expression: "data.equipmentNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "설비관리번호",
                              name: "equipmentNum",
                            },
                            model: {
                              value: _vm.data.equipmentNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentNum", $$v)
                              },
                              expression: "data.equipmentNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "설비명",
                              name: "equipmentName",
                            },
                            model: {
                              value: _vm.data.equipmentName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentName", $$v)
                              },
                              expression: "data.equipmentName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-equip-class", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "설비유형",
                              name: "equipmentTypeCd",
                            },
                            model: {
                              value: _vm.data.equipmentTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentTypeCd", $$v)
                              },
                              expression: "data.equipmentTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-func-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable,
                              label: "기능위치",
                              name: "funcLocationCd",
                            },
                            model: {
                              value: _vm.data.funcLocationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "funcLocationCd", $$v)
                              },
                              expression: "data.funcLocationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.updateMode,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              required: true,
                              label: "관리부서",
                              parentCheckDepts: _vm.data.managementDepts,
                              plantCd: _vm.data.plantCd,
                              name: "managementDepts",
                            },
                            model: {
                              value: _vm.data.managementDepts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "managementDepts", $$v)
                              },
                              expression: "data.managementDepts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-process", {
                            attrs: {
                              editable: _vm.editable,
                              label: "단위공정",
                              multiple: "multiple",
                              name: "subProcessCd",
                            },
                            model: {
                              value: _vm.data.subProcessCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "subProcessCd", $$v)
                              },
                              expression: "data.subProcessCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd: "MDM_EQUIP_GRADE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "equipGradeCd",
                              label: "설비등급",
                            },
                            model: {
                              value: _vm.data.equipGradeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipGradeCd", $$v)
                              },
                              expression: "data.equipGradeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-1" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "수량",
                              name: "amount",
                              type: "number",
                            },
                            model: {
                              value: _vm.data.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "amount", $$v)
                              },
                              expression: "data.amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-cost-center", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable,
                              label: "코스트센터",
                              name: "costCenterCd",
                            },
                            model: {
                              value: _vm.data.costCenterCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "costCenterCd", $$v)
                              },
                              expression: "data.costCenterCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "최초작성자",
                              name: "regUserName",
                              type: "regUserName",
                            },
                            model: {
                              value: _vm.data.regUserName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regUserName", $$v)
                              },
                              expression: "data.regUserName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "최초작성일시",
                              name: "regDtStrTime",
                              type: "regDtStrTime",
                            },
                            model: {
                              value: _vm.data.regDtStrTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regDtStrTime", $$v)
                              },
                              expression: "data.regDtStrTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "최근수정자",
                              name: "chgUserName",
                              type: "chgUserName",
                            },
                            model: {
                              value: _vm.data.chgUserName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "chgUserName", $$v)
                              },
                              expression: "data.chgUserName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "최근수정일시",
                              name: "chgDtStrTime",
                              type: "chgDtStrTime",
                            },
                            model: {
                              value: _vm.data.chgDtStrTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "chgDtStrTime", $$v)
                              },
                              expression: "data.chgDtStrTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설비가동 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "시작 가동일",
                              name: "operationStartDate",
                            },
                            on: { datachange: _vm.getHolidaies },
                            model: {
                              value: _vm.data.operationStartDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "operationStartDate", $$v)
                              },
                              expression: "data.operationStartDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              type: "number",
                              suffix: "시간",
                              label: "하루 가동시간",
                              name: "operateTime",
                            },
                            on: { dataChange: _vm.dataChange },
                            model: {
                              value: _vm.data.operateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "operateTime", $$v)
                              },
                              expression: "data.operateTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "휴무일 여부",
                              name: "holidayFlag",
                            },
                            on: { datachange: _vm.dataChange },
                            model: {
                              value: _vm.data.holidayFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "holidayFlag", $$v)
                              },
                              expression: "data.holidayFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable,
                              type: "number",
                              suffix: "시간",
                              label: "부하시간",
                              name: "lapseTime",
                            },
                            model: {
                              value: _vm.data.lapseTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "lapseTime", $$v)
                              },
                              expression: "data.lapseTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable,
                              type: "number",
                              suffix: "시간",
                              label: "총 가동시간",
                              name: "operationSumTime",
                            },
                            model: {
                              value: _vm.data.operationSumTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "operationSumTime", $$v)
                              },
                              expression: "data.operationSumTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable,
                              type: "number",
                              suffix: "%",
                              label: "가동률",
                              name: "operationPercent",
                            },
                            model: {
                              value: _vm.data.operationPercent,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "operationPercent", $$v)
                              },
                              expression: "data.operationPercent",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c(
                  "c-table",
                  {
                    ref: "table",
                    attrs: {
                      title: "가동시간 목록(개정포함)",
                      columns: _vm.grid.columns,
                      data: _vm.equipList,
                      gridHeight: _vm.grid.height,
                      editable: false,
                      hideBottom: true,
                      isExcelDown: false,
                      filtering: false,
                      isFullScreen: false,
                      columnSetting: false,
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "suffixTitle" },
                      [
                        _vm.lapSum
                          ? _c(
                              "q-chip",
                              {
                                attrs: {
                                  dense: "",
                                  color: "gray",
                                  "text-color": "main-custom",
                                  icon: "bookmark",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s("부하시간 합계: " + _vm.lapSum) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.operSum
                          ? _c(
                              "q-chip",
                              {
                                attrs: {
                                  dense: "",
                                  color: "gray",
                                  "text-color": "main-custom",
                                  icon: "bookmark",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s("가동시간 합계: " + _vm.operSum) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 col-lg-3" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치관련 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-vendor", {
                            attrs: {
                              editable: _vm.editable,
                              label: "정비업체",
                              name: "maintenVendorCd",
                            },
                            model: {
                              value: _vm.data.maintenVendorCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "maintenVendorCd", $$v)
                              },
                              expression: "data.maintenVendorCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: { label: "설치일", name: "installDate" },
                            model: {
                              value: _vm.data.installDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "installDate", $$v)
                              },
                              expression: "data.installDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-vendor", {
                            attrs: {
                              editable: _vm.editable,
                              label: "설치업체",
                              name: "installVendor",
                            },
                            model: {
                              value: _vm.data.installVendor,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "installVendor", $$v)
                              },
                              expression: "data.installVendor",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: { label: "가동일", name: "opertationDate" },
                            model: {
                              value: _vm.data.opertationDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "opertationDate", $$v)
                              },
                              expression: "data.opertationDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 col-lg-3" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치상태 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "검사합격번호",
                              name: "checkPassNo",
                            },
                            model: {
                              value: _vm.data.checkPassNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "checkPassNo", $$v)
                              },
                              expression: "data.checkPassNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: { label: "합격일", name: "checkPassDate" },
                            model: {
                              value: _vm.data.checkPassDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "checkPassDate", $$v)
                              },
                              expression: "data.checkPassDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "폐기여부",
                              name: "discardFlag",
                            },
                            model: {
                              value: _vm.data.discardFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "discardFlag", $$v)
                              },
                              expression: "data.discardFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              label: "폐기일",
                              name: "discardDate",
                            },
                            model: {
                              value: _vm.data.discardDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "discardDate", $$v)
                              },
                              expression: "data.discardDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "점검관련 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "예방점검 여부",
                              name: "operFlag",
                            },
                            model: {
                              value: _vm.data.operFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "operFlag", $$v)
                              },
                              expression: "data.operFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "selfCycleCd",
                              label: "예방점검주기",
                            },
                            model: {
                              value: _vm.data.selfCycleCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "selfCycleCd", $$v)
                              },
                              expression: "data.selfCycleCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "PM여부",
                              name: "pmFlag",
                            },
                            model: {
                              value: _vm.data.pmFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "pmFlag", $$v)
                              },
                              expression: "data.pmFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "pmCycleCd",
                              label: "PM주기",
                            },
                            model: {
                              value: _vm.data.pmCycleCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "pmCycleCd", $$v)
                              },
                              expression: "data.pmCycleCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-multi-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd: "EQUIP_RELATED_LAW_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "설비관련 법규(인허가)",
                              name: "relatedLaws",
                            },
                            model: {
                              value: _vm.data.relatedLaws,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "relatedLaws", $$v)
                              },
                              expression: "data.relatedLaws",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-md-6 col-lg-6",
                staticStyle: { "margin-top": "14px" },
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable,
                    isPhoto: true,
                    label: "설비 사진",
                  },
                }),
              ],
              1
            ),
            _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }